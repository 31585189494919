<template>
  <div class="container">
    <p class="title">《隐私政策》</p>
    <p><strong>强生（上海）医疗器材有限公司</strong>（以下简称“强生”或“我们”）通过CNV微信订阅号数字化平台，传递医学学术内容，向您提供学术活动信息、医学专业知识分享、产品介绍等服务。</p><br/>

    <p>为保证您可以通过CNV微信订阅号平台浏览学术活动信息、学术文字和视频、产品介绍及其他内容，我们需要请您提供以下个人信息，以完成实名注册、身份验证和为您提供前述服务：</p>
    <ul class="ul">
      <li>您的姓名；</li>
      <li>您的性别；</li>
      <li>您的手机号码；</li>
      <li>您所任职的医院或机构名称、所属科室名称及您的职称。</li>
    </ul><br/>

    <p>此外，为帮助我们了解您对本平台所提供内容和服务的反馈和更好地改进和提高我们提供的内容和服务，我们还可能收集您在CNV微信订阅号平台上的用户行为数据。</p><br/>

    <p>强生承诺使用合理的组织、技术和管理措施来保护您的个人信息和数据；防止未经授权或意外访问、销毁、修改、封锁、复制、散布及其他未经授权的处理。强生将基于法律法规规定或限于实现本隐私声明目的所必要的最短期限内保存您的个人信息和数据。</p><br/>
    
    <p>您理解并同意，为满足本隐私声明的目的，强生可能委托第三方供应商来处理您的个人信息和数据，强生会根据法律法规的要求，与第三方供应商严格根据本隐私声明的内容，约定委托处理的目的、期限、处理方式、个人信息范围、保护措施以及双方的权利和义务等，并对第三方供应商的个人信息和数据处理活动进行监督。</p><br/>
  
    <p>更多关于强生处理您个人信息和数据的情况，您可以阅读强生隐私政策（<a href="https://www.jnjmedtech.cn/zh-CN/policies-privacy">https://www.jnjmedtech.cn/zh-CN/policies-privacy</a>）。您可以通过联系强生隐私政策中提供的联系方式行使您享有的个人信息主体权利。</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  font-size: 12px;
  padding: 12px;
  box-sizing: border-box;
  background-color: #F8F8F8;
  .title {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  p {
    line-height: 2;
  }
  .ul {
    list-style-type: disc;
    margin-left: 30px;
    li {
      line-height: 2;
    }
  }
  .child_ul {
    list-style-type: circle;
    margin-left: 14px;
    li {
      line-height: 2;
    }
  }
  a {
    color: #1989fa;
  }
}
</style>